<template>
  <div class="user-card AdminVerificationsCard" v-if="user">
    <div class="user-card-content" :style="`--picture:url(${user.picture})`">
      <div class="user-card-left">
        <UserAvatar :user="user" :style="{ margin: 'var(--mpadding)' }" />
      </div>
      <div class="user-card-right">
        <div class="user-card-status">
          <div class="user-card-user">
            <div class="user-card-info-label skeleton">{{ user.name }}</div>
            <div class="user-card-info-email skeleton">{{ user.email }}</div>
            <div class="user-card-info-type">
              <span class="doctype" skeleton>
                <iconic name="address_card" />
                {{ $locale[user.identity.id_type] }}
              </span>
            </div>
          </div>
          <div class="user-card-resume">
            <div>
              <span class="mount_label">{{ date_locale(user.identity.verifyStartDate) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-card-admin">
      <UserIdentityDocs :user="user" />
      <UserInfoCard :user="user" />
      <AdminVerificationsStatusSelect :selected="verifyStatus" @select="(opt) => (verifyStatus = opt)" skeleton />
      <AdminVerificationsComments
        ref="comments"
        :comments="user.messages"
        :total="user.totalMessages"
        @cancel="answerCancel"
        @accept="answerSend"
        @input="inputMessage"
        @more="getComments"
        :loading="loading"
        :loadingMore="loadingMore"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["card", "user"],
  components: {
    UserIdentityDocs: () => import("../user/UserIdentityDocs.vue"),
    UserInfoCard: () => import("../user/UserInfoCard.vue"),
    AdminVerificationsStatusSelect: () => import("./AdminVerificationsStatusSelect.vue"),
    AdminVerificationsComments: () => import("./AdminVerificationsComments.vue"),
  },
  data: function() {
    return {
      loading: false,
      loadingMore: false,
      target: null,
      message: "",
      verifyStatus: this.user.identity.verifyStatus,
      pageComments: 0,
    };
  },
  methods: {
    answerCancel: function() {
      this.message = "";
      this.target.innerHTML = "";
    },
    inputMessage: function({ target }) {
      let [message] = [target.innerHTML];
      this.target = target;
      this.message = message;
    },
    answerSend: async function() {
      this.isLoading(true);
      try {
        const data = {
          userId: this.user._id,
          comment: this.message,
          verifyStatus: this.verifyStatus,
        };
        const response = await this.$api.post(`${process.env.VUE_APP_API_HOST}admin/verifications-answer`, data);
        this.answerSuccess(response.data);
      } catch (error) {
        this.ialert.go({ title: "Error", message: error, icon: "error" });
      }
      this.isLoading(false);
    },
    answerSuccess: function({ message, success, answer }) {
      if (success) {
        answer.user = this.$user;
        this.user.messages.unshift(answer);
        this.user.hasResponse = true;
        this.$refs.comments.commentCancel();
        this.$store.state.appKey++;
        return this.answerCancel();
      }
      return this.ialert.go({ title: "Error", message: message, icon: "error" });
    },
    words: function(key) {
      return this.$store.state.locale.words[key];
    },
    getComments: async function() {
      this.pageComments++;
      this.loadingMore = true;

      try {
        let query = `&page=${this.pageComments}&userId=${this.user._id}&start=1&limit=10`;
        query += `&type=user-verification-answer`;
        const result = await this.$api.get(`${process.env.VUE_APP_API_HOST}admin/user-comments?${query}`);

        if (this.pageComments <= 1) {
          this.user.messages = result.data.items;
        } else {
          this.user.messages.push(...result.data.items);
        }

        console.log("result.data.comments", result.data.items);
      } catch (error) {
        this.pageComments--;
        console.log(error);
      }

      this.loadingMore = false;
    },
  },
};
</script>

<style lang="scss">
.user-card {
  position: relative;
  margin: 0 0 $mpadding 0;
  @include shadow;
  border-radius: $mpadding/2;
  overflow: hidden;
  &-content {
    display: flex;
    width: 100%;
    background-color: #fff;
  }
  &-admin {
    background-color: #fff;
  }
  .user-documents {
    padding: $mpadding;
    border-top: solid 1px $alto;
    border-bottom: solid 1px $alto;
  }
  .UserInfoCard {
    border-bottom: solid 1px $alto;
  }
  &-left {
    display: flex;
  }
  &-icon {
    background-color: var(--color);
    background-image: var(--picture);
    background-size: cover;
    background-position: center;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    font-size: var(--iconSize);
    margin: $mpadding;
    .iconic {
      @include Flex(inherit, center, center);
    }
  }
  &-info {
    padding: $mpadding $mpadding;
    display: flex;
    align-items: center;
    &-label {
      font-weight: bold;
      font-size: 105%;
    }
    &-email {
      opacity: 0.8;
    }
    &-reason {
      margin: $mpadding/2 0 0 0;
      font-weight: bold;
      font-size: 90%;
      display: flex;
      gap: $mpadding/2;
      .label {
        color: $primary-color;
      }
      .post {
        opacity: 0.7;
        cursor: pointer;
      }
    }
    &-message {
      margin: $mpadding/2 $mpadding $mpadding/2 0;
      opacity: 0.8;
    }
    .progress-bar {
      margin-top: $mpadding/2;
    }
  }
  &-right {
    flex: 1;
    padding: $mpadding 0 $mpadding 0;
    .mount {
      font-size: 120%;
      font-weight: bold;
      margin: $mpadding/2 $mpadding $mpadding/2 0;
      .iconic {
        display: inline-flex;
        margin: 0 0 $mpadding/2 0;
      }
    }
    .sign {
      font-weight: bold;
      margin: 0 $mpadding/4 0 0;
      font-size: 80%;
    }
    .mount_label {
      margin: 0 $mpadding $mpadding/2 0;
      font-size: 85%;
      opacity: 0.7;
      display: block;
    }
    .tooltiptext {
      font-size: 70%;
    }
  }
  &-status {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
  &-resume {
    text-align: right;
  }
  &-comments {
    padding: 0 $mpadding $mpadding $mpadding;
    border-top: solid 1px $alto;
    .message-box {
      [textarea] {
        border-radius: $mpadding;
        background-color: rgba(9, 30, 66, 0.01);
        min-height: auto;
        padding: $mpadding;
        margin: $mpadding 0 0 0;
      }
    }
    .confirm {
      margin: $mpadding 0 0 0;
    }
  }
  .doctype {
    display: inline-flex;
    align-items: center;
    gap: $mpadding/2;
    border: solid 1px $alto;
    padding: $mpadding/4 $mpadding/2;
    margin: $mpadding/2 0 0 0;
    border-radius: 2pc;
    .iconic {
      color: $primary-color;
    }
  }
}
</style>
